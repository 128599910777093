@use "../../scss/variables/theme";

.root {
  color: theme.$black;
  font-size: 12px;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  cursor: pointer;

  .input {
    &:hover {
      .box {
        border-color: theme.$lightblue;
      }
    }

    .box {
      background-color: theme.$white;
      border: 1px solid theme.$light-border;
      position: relative;
      top: 0;
      left: 0;
      display: block;
      width: 16px;
      height: 16px;
      direction: ltr;
      border-radius: 2px;
      border-collapse: separate;
      transition: all 0.3s;

      &:after {
        border: 2px solid theme.$white;
        position: absolute;
        display: table;
        top: 50%;
        left: 22%;
        width: 5.7px;
        height: 9.15px;
        border-top: 0;
        border-left: 0;
        opacity: 0;
        transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
        transform: rotate(45deg) scale(0) translate(-50%, -50%);
        content: " ";
      }
    }
  }

  .checked {
    .box {
      background-color: theme.$lightblue;
      border-color: theme.$lightblue;

      &:after {
        opacity: 1;
        transform: rotate(45deg) scale(1) translate(-50%, -50%);
        transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
      }
    }
  }

  .error {
    .box {
      border-color: theme.$dark-error;
    }

    &:hover {
      .box {
        border-color: theme.$dark-error;
      }
    }
  }

  .label {
    padding-left: 6px;
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;

  .input {
    .box {
      border-color: theme.$light-border !important;
    }
  }
}
