@use "../../../scss/variables/theme";

.root {
  display: flex;
  flex-direction: column;
  opacity: 1;
  transition: opacity 0.3s;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
