.flex-1 {
  flex: 1;
}

.pointer {
  cursor: default;

  &:hover {
    cursor: pointer;
  }
}

.square-button {
  min-width: 38px;
  min-height: 38px;

  .fas {
    margin: 0 !important;
  }
}

.Toastify__close-button:hover {
  background-color: transparent !important;
  border-color: transparent !important;
}

.mud-guard {
  position: fixed !important;
  margin: 20px auto;
}

.fit-content {
  min-width: max-content;

  .input-field {
    max-width: 50px;
    margin-right: 3rem;
  }
}

.order-icon {
  opacity: 0.5;
  cursor: default;

  &:hover {
    cursor: grabbing;
  }
}

.back-icon {
  opacity: 0.8;
  &:hover {
    opacity: 1;
    transition: all 0.5s;
    cursor: pointer;
  }
}

input:focus,
select:focus,
textarea:focus {
  box-shadow: none !important;
}

.vrm-input {
  border-radius: 2px !important;
  padding-top: 7px;
  padding-bottom: 7px;
  line-height: 1.6;
}
