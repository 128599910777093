@use "sass:map";
@use "variables/theme";
@use "common/common";

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  background-color: theme.$white;
  margin: 0;
  font-size: 0.8rem;
}

#nprogress .bar {
  background-color: theme.$lightblue;
}
