@use "../../../scss/variables/theme";

.error {
  color: theme.$dark-error;
  font-weight: 400;
  font-size: 11px;
  margin-top: 2px;
}

.label {
  color: theme.$black;
  font-weight: 400;
  font-size: 13px;
  margin-bottom: 2px;
}
