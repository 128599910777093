@use "../../../scss/variables/theme";

.root {
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  line-height: unset;
  list-style: none;
  display: flex;

  button:not(:last-child) {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  button:not(:first-child) {
    border-left: none !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}

.filled {
  button:not(:last-child) {
    border-right-color: theme.$dark-border !important;
  }
}

.full-width {
  width: 100%;
}
